export const PRODUCT_TYPE = 'products';
export const LINE_TYPE = 'lines';

export enum CigarTypes {
  PRODUCTS = 'products',
  LINES = 'lines',
}

export interface ICigarDistinct {
  ProductId?: number;
  LineId?: number;
  Name: string;
  ListNames: string;
  ImageUrl?: string;
  Rating: IRatingSummary;
  UserRating: {
    Rating: number | null;
    Comment: string | null;
  };
}

export interface ICigarPic {
  ImageUrl?: string;
  Sort: number;
}

export interface ITopRatedCigar {
  CigarDetails: ICigarLite;
  LineId: number;
  BrandName: string;
  Rating: Rating;
  OverallRating: Rating;
}

export interface ITopScannedCigar {
  CigarDetails: ICigarLite;
  LineId: number;
  BrandName: string;
  TotalScans: number;
}

interface Rating {
  AverageRating: number;
  RatingCount: number;
}
export interface ICigarLite {
  ProductId?: number;
  LineId?: number;
  Name: string;
  RatingSummary?: IRatingSummary;
  ImageUrl?: string;
  ImageUrlSmall?: string;
  MinBoxQty?: number;
  MaxBoxQty?: number;
  Prices?: Prices;
  PartnerPrices?: PartnerPrices;
  MyRating?: IMyRatingLite;
  MyNote?: ICigarNote;
  IsCustom?: boolean;
  SmokingTime?: number;
}
export interface ICigar extends Omit<ICigarLite, 'MyRating'> {
  Description?: string;
  CanonicalUrl?: string;
  UserImageUrl?: any;
  ImageOfSingleUrl?: string;
  ImageOfSingleWidth?: number;
  ImageOfSingleHeight?: number;
  Attributes?: Attributes;
  CustomUserId?: any;
  CustomUUID?: any;
  SocialPosts?: number;
  AdditionalAttributes?: any[];
  Images?: any[];
  Tags?: ReviewTag[];
  Shapes?: ICigarLineShape[];
  BandHistory?: BandHistory[];
  RelatedLines?: ICigarRelatedLine[];
  MyCigarFeatures?: IMyCigarFeatures;
  MyRating?: IMyRating;
}

export interface ICigarLineShape {
  Id: number; // this is the product ID
  LineId: number;
  Name: string;
  ImageUrl: string | null;
  ImageUrlSmall: string | null;
  ImageOfSingleUrl: string | null;
  ImageOfSingleWidth: number | null;
  ImageOfSingleHeight: number | null;
  Attributes: ShapeAttributes;
  Rating: ICigarRating;
  Prices: Prices;
  PartnerPrices: PartnerPrices;
  MinBoxQty: number;
  MaxBoxQty: number;
  AdditionalAttributes: any[];
}

interface ShapeAttributes {
  Length: string;
  Shape: string;
  RingGauge: number;
  CARating?: any;
  CIRating?: any;
}

export interface IShape {
  Id: number;
  Name: string;
  Desc: string;
  Width: number;
  Height: number;
}

export interface ICigarFeaturesForm {
  SmokingTime: number;
}
export interface IMyCigarFeatures {
  Id: number;
  UserId: string;
  UUID: string;
  ProductId: number;
  LineId?: any;
  CreatedOn: string;
  UpdatedOn?: any;
  SmokingTime: number;
}

export interface ICigarReviewForm {
  Comment: string;
  DrawRating: number;
  AppearanceRating: number;
  BurnRating: number;
  AromaRating: number;
  TasteRating: number;
}

export interface IMyRating extends ICigarReviewForm {
  UserId?: string;
  Uuid?: string;
  Id?: number;
  Rating?: number;
  CreatedOn?: string;
  UpdatedOn?: string;
}
export interface IMyRatingLite {
  ReviewId: number;
  Rating: number;
  Comment: string | null;
  CreatedOn: string;
}

export interface ICigarNote {
  Id: number;
  ProductId?: any;
  LineId: number;
  UserId: string;
  UUID: string;
  Note: string;
  CreatedOn: string;
  UpdatedOn: string;
}

interface BandHistory {
  ImageUrl: string;
  ImageType: string;
  Order: number;
}

export interface ReviewTag {
  Tag: string;
  Weight: number;
}

interface Attributes {
  ManufacturerValueId?: any;
  StrengthValueId: number;
  OriginValueId: number;
  WrapperValueId: number;
  BinderValueId: number;
  FillerValueId: number;
  WrapperColorValueId: number;
  Manufacturer?: any;
  ManufacturerDescription?: any;
  Origin?: string;
  Strength?: string;
  Wrapper?: string;
  WrapperColor?: string;
  WrapperColorDescription: string;
  Binder?: string;
  BinderDescription?: string;
  Filler?: string;
  FillerDescription?: any;
  RingGauge: number;
  Length: string;
  Section: string;
  Shape?: string;
  ShapeDescription?: string;
  SinglePackaging?: string;
  IsSpecific: boolean;
  MasterLine?: any;
  CARating?: any;
  CIRating?: any;
}

interface PartnerPrices {
  PartnerSinglePriceMin?: any;
  PartnerSinglePriceMax?: any;
  PartnerBoxPriceMin?: any;
  PartnerBoxPriceMax?: any;
}

interface Prices {
  SinglePriceMin: number;
  SinglePriceMax: number;
  BoxPriceMin: number;
  BoxPriceMax: number;
  DisplayPartnerPrices?: any;
}
export interface ICigarRelatedLine {
  Id: number;
  Name: string;
  ImageUrl: string;
  ImageUrlSmall: string;
  Rating: ICigarRating;
  Prices: Prices;
  PartnerPrices: PartnerPrices;
}

export interface IRatingSummary extends ICigarRating {
  Rated5?: number;
  Rated4?: number;
  Rated3?: number;
  Rated2?: number;
  Rated1?: number;
}
export interface ICigarRating {
  AverageRating: number;
  RatingCount: number;
}
export interface ICigarReview {
  Extra: Extra;
  ReviewId: number;
  NeptuneReviewId?: any;
  UserId: string;
  UUID: string;
  MemberId?: any;
  FirstName: string;
  LastName: string;
  Nickname: string;
  ProductId: number;
  LineId: number;
  Rating: number;
  Comment: string;
  DrawRating: number;
  AppearanceRating: number;
  BurnRating: number;
  AromaRating: number;
  TasteRating: number;
  CreatedOn: string;
  UpdatedOn: string;
}

interface Extra {
  ProductId: number;
  LineId: number;
  ProductAverageRating: number;
  ProductRatingCount: number;
  ProductRated5: number;
  ProductRated4: number;
  ProductRated3: number;
  ProductRated2: number;
  ProductRated1: number;
  LineAverageRating: number;
  LineRatingCount: number;
  LineRated5: number;
  LineRated4: number;
  LineRated3: number;
  LineRated2: number;
  LineRated1: number;
}

export interface ICustomCigarForm {
  Name: string;
  Description: string;
  LineId?: any;
  ManufacturerValueId: number;
  StrengthValueId: number;
  OriginValueId?: any;
  WrapperValueId?: any;
  BinderValueId?: any;
  FillerValueId?: any;
  WrapperColorValueId?: any;
}

export interface ICigarLineInfo {
  LineId: number;
  Name: string;
  Description: string;
  ManufacturerAttributeId: number;
  ManufacturerValueId: number;
  Manufacturer: string;
  StrengthAttributeId: number;
  StrengthValueId: number;
  Strength: string;
  OriginAttributeId: number;
  OriginValueId: number;
  Origin: string;
  WrapperAttributeId: number;
  WrapperValueId: number;
  Wrapper: string;
  BinderAttributeId: number;
  BinderValueId: number;
  Binder: string;
  FillerAttributeId: number;
  FillerValueId: number;
  Filler: string;
  WrapperColorAttributeId: number;
  WrapperColorValueId: number;
  WrapperColor: string;
}
