import { on, createReducer } from '@ngrx/store';
import _get from 'lodash-es/get';

import { ICigarsSearchState, IProductSearch } from '@models';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import * as userActions from '@store/actions/user';
import * as CigarsSearchActions from '../actions/cigars-search';

export const NAMESPACE = 'cigarsSearch';

export const adapter: EntityAdapter<IProductSearch> =
  createEntityAdapter<IProductSearch>({
    selectId: (entity: IProductSearch) => entity.GeneratedId,
  });

export const initialState: ICigarsSearchState = adapter.getInitialState({
  isLoading: false,
  showMore: false,
  init: false,
  error: '',
  total: 0,
  term: '',
  filters: null,
  list: 'all',
});

export const CigarsSearchReducer = createReducer(
  initialState,
  on(CigarsSearchActions.CigarsSearchRequestAction, (state) => ({
    ...state,
    isLoading: true,
    error: '',
  })),
  on(CigarsSearchActions.CigarsSearchUpdateTermAction, (state, { term }) => ({
    ...state,
    term,
  })),
  on(CigarsSearchActions.CigarsSearchUpdateListAction, (state, { list }) => ({
    ...state,
    list,
  })),
  on(
    CigarsSearchActions.CigarsSearchUpdateFiltersAction,
    (state, { filters }) => ({
      ...state,
      filters,
    })
  ),
  on(
    CigarsSearchActions.CigarsSearchSuccessAction,
    (state, { cigars, skip, take, total }) => {
      const ids = [
        ...(skip === 0 ? [] : state.ids),
        ...cigars.map((e) => e.GeneratedId),
      ] as string[];
      return {
        ...state,
        entities: adapter.upsertMany(cigars, state).entities,
        ids,
        showMore: total > ids.length,
        isLoading: false,
        init: true,
        total,
        error: '',
      };
    }
  ),
  on(CigarsSearchActions.CigarsSearchErrorAction, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(userActions.LogoutSuccess, userActions.LoginSuccess, () => initialState)
);

export default CigarsSearchReducer;
