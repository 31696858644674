import { ICigar, ICigarLineShape, ICigarLite } from './cigar';

export enum ICigarLogListEnum {
  JOURNAL_LIST = 'journal',
  FAVORITES_LIST = 'favorites',
  WISH_LIST = 'wishlist',
}

export type ICigarLogListIds = 'favorites' | 'wishlist' | 'journal';
export type ICigarLogHumidorId = string;

export type ICigarLogListType = ICigarLogListIds | ICigarLogHumidorId;

export enum ICigarLogListSortNameEnum {
  DATE = 'date',
  NAME = 'name',
  RATING = 'rating',
  MYRATING = 'myrating',
}
export interface ICigarLogFilters {
  sort: ICigarLogListSortNameEnum;
  order: 'asc' | 'desc';
  search: string;
}

export interface ICigarLog<T = ICigarLite | ICigar> {
  Id: number;
  Date: string;
  ModifiedOn?: string;
  UserId: string;
  UUID: string;
  ProductId?: number;
  LineId?: number;
  List: ICigarLogListType;
  Quantity: number;
  Location?: any;
  LocationModified: boolean;
  Price?: any;
  RecognitionId?: any;
  UserImageUrl?: string;
  CigarDetails: T;
}
export interface ICigarLogStored extends Omit<ICigarLog, 'CigarDetails'> {
  CigarDetails: string;
}

export interface ICigarLogActionProps {
  listType: ICigarLogListType;
  LineId?: number;
  ProductId?: number;
  cigarLogId?: number;
  UserImageUrl?: string;
  Location?: string;
  RecognitionId?: number;
}
