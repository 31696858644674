import { createAction, props } from '@ngrx/store';
import { IUserShort } from '@models/user';

export enum ActionTypes {
  'USERS_SEARCH_UPDATE_TERM' = 'USERS_SEARCH_UPDATE_TERM',
  'USERS_SEARCH_REQUEST' = 'USERS_SEARCH_REQUEST',
  'USERS_SEARCH_SUCCESS' = 'USERS_SEARCH_SUCCESS',
  'USERS_SEARCH_ERROR' = 'USERS_SEARCH_ERROR',
  'USERS_SEARCH_FOLLOW_UPDATE' = 'USERS_SEARCH_FOLLOW_UPDATE',
}

export const UsersSearchUpdateTermAction = createAction(
  ActionTypes.USERS_SEARCH_UPDATE_TERM,
  props<{
    term: string;
  }>()
);

export const UsersSearchFollowUpdateAction = createAction(
  ActionTypes.USERS_SEARCH_FOLLOW_UPDATE,
  props<{
    followed: boolean;
    userId: string;
  }>()
);

export const UsersSearchRequestAction = createAction(
  ActionTypes.USERS_SEARCH_REQUEST,
  props<{
    skip?: number;
    take?: number;
  }>()
);
export const UsersSearchSuccessAction = createAction(
  ActionTypes.USERS_SEARCH_SUCCESS,
  props<{
    users: IUserShort[];
    skip?: number;
    take?: number;
    total: number;
  }>()
);
export const UsersSearchErrorAction = createAction(
  ActionTypes.USERS_SEARCH_ERROR,
  props<{ error: string }>()
);

export type ActionsUnion =
  | typeof UsersSearchUpdateTermAction
  | typeof UsersSearchRequestAction
  | typeof UsersSearchSuccessAction
  | typeof UsersSearchErrorAction
  | typeof UsersSearchFollowUpdateAction;
