import { IUsersSearchState } from '@models';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IUserShort } from '@models/user';
import * as userActions from '@store/actions/user';

import * as UsersSearchActions from '../actions/users-search';

export const NAMESPACE = 'usersSearch';

export const adapter: EntityAdapter<IUserShort> =
  createEntityAdapter<IUserShort>({
    selectId: (entity: IUserShort) => entity.UserId,
  });

export const initialState: IUsersSearchState = adapter.getInitialState({
  isLoading: false,
  showMore: false,
  error: '',
  total: 0,
  term: '',
});

export const UsersSearchReducer = createReducer(
  initialState,
  on(UsersSearchActions.UsersSearchRequestAction, (state, { skip }) => {
    if (skip === 0) {
      return adapter.removeAll({
        ...state,
        isLoading: true,
        error: '',
      });
    }
    return {
      ...state,
      isLoading: true,
      error: '',
    };
  }),
  on(UsersSearchActions.UsersSearchUpdateTermAction, (state, { term }) => ({
    ...state,
    term,
  })),
  on(
    UsersSearchActions.UsersSearchFollowUpdateAction,
    (state, { followed, userId }) => {
      const user = state.entities[userId];
      if (user) {
        return {
          ...state,
          entities: adapter.updateOne(
            {
              id: userId,
              changes: { ...user, Followed: followed },
            },
            state
          ).entities,
        };
      }
      return state;
    }
  ),
  on(
    UsersSearchActions.UsersSearchSuccessAction,
    (state, { users, skip, take, total }) => {
      const ids = [
        ...(skip === 0 ? [] : state.ids),
        ...users.map((e) => e.UserId),
      ] as string[];
      return {
        ...state,
        entities: adapter.upsertMany(users, state).entities,
        ids,
        showMore: total > ids.length,
        isLoading: false,
        total,
        error: '',
      };
    }
  ),
  on(UsersSearchActions.UsersSearchErrorAction, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(userActions.LogoutSuccess, userActions.LoginSuccess, () => initialState)
);

export default UsersSearchReducer;
