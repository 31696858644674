import { createAction, props } from '@ngrx/store';

import { IProductSearchFilters, IProductSearch, SearchTypes } from '@models';

export enum ActionTypes {
  'CIGARS_SEARCH_UPDATE_FILTERS' = 'CIGARS_SEARCH_UPDATE_FILTERS',
  'CIGARS_SEARCH_UPDATE_TERM' = 'CIGARS_SEARCH_UPDATE_TERM',
  'CIGARS_SEARCH_UPDATE_LIST' = 'CIGARS_SEARCH_UPDATE_LIST',
  'CIGARS_SEARCH_REQUEST' = 'CIGARS_SEARCH_REQUEST',
  'CIGARS_SEARCH_SUCCESS' = 'CIGARS_SEARCH_SUCCESS',
  'CIGARS_SEARCH_ERROR' = 'CIGARS_SEARCH_ERROR',
}

export const CigarsSearchUpdateListAction = createAction(
  ActionTypes.CIGARS_SEARCH_UPDATE_LIST,
  props<{
    list: SearchTypes;
  }>()
);

export const CigarsSearchUpdateTermAction = createAction(
  ActionTypes.CIGARS_SEARCH_UPDATE_TERM,
  props<{
    term: string;
  }>()
);

export const CigarsSearchUpdateFiltersAction = createAction(
  ActionTypes.CIGARS_SEARCH_UPDATE_FILTERS,
  props<{
    filters: IProductSearchFilters;
  }>()
);
export const CigarsSearchRequestAction = createAction(
  ActionTypes.CIGARS_SEARCH_REQUEST,
  props<{
    skip?: number;
    take?: number;
  }>()
);
export const CigarsSearchSuccessAction = createAction(
  ActionTypes.CIGARS_SEARCH_SUCCESS,
  props<{
    cigars: IProductSearch[];
    skip?: number;
    take?: number;
    total: number;
  }>()
);
export const CigarsSearchErrorAction = createAction(
  ActionTypes.CIGARS_SEARCH_ERROR,
  props<{ error: string }>()
);

export type ActionsUnion =
  | typeof CigarsSearchUpdateListAction
  | typeof CigarsSearchUpdateTermAction
  | typeof CigarsSearchUpdateFiltersAction
  | typeof CigarsSearchRequestAction
  | typeof CigarsSearchSuccessAction
  | typeof CigarsSearchErrorAction;
